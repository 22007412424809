import * as React from "react";
import HeadSeo from "../components/HeadSeo.js";
import Layout from "../components/Layout.js";
import DynamicSidebar from "../components/DynamicSidebar";
import Default from "../_contenthub/website.json";

const { aboutUsPage, myContentInfo } = Default;

export const Head = () => {
  return (
    <HeadSeo
      title={`About ${myContentInfo.websiteTitle}`}
      description={
        aboutUsPage.seoDescription ??
        `The team at ${myContentInfo.websiteTitle} are a diverse team of anonymous authors who are dedicated to provide information and research from across the globe.`
      }
      seoImg={`aboutus.jpg`}
    ></HeadSeo>
  );
};

const AboutPage = () => {
  return (
    <>
      <Layout>
        <main>
          <div className="main-wrapper">
            <div className="primary-wrapper">
              <div className="layout-rightsidebar">
                <div className="main backpage">
                  <div
                    dangerouslySetInnerHTML={{
                      __html: aboutUsPage?.bodytext,
                    }}
                  />
                </div>
                {/* - - -  */}
                <DynamicSidebar onlyBlogs="true" />
              </div>
            </div>
          </div>
        </main>
      </Layout>
    </>
  );
};

export default AboutPage;
